// Here you can add other styles

.jsonConten {
  border: 1px solid $gray-400;
  border-radius: 4px;
  padding: 6px;
  background-color: $gray-100;
}

.jsonCard {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid $gray-100;
  border-radius: 4px;

  &:hover {
    border: 1px solid $gray-300;
    background-color: white;
  }

  .jsonCardCateg {
    font-weight: bold;
    width: 240px;
    text-align: right;
  }

  .jsonCardConten {
    margin-left: 12px;
    padding-left: 12px;
    padding-right: 120px;
    border-left: 1px solid $gray-400;
    flex: 1;

    img {
      float: left;
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }

  .jsonControlConten {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.imgCnt {
  width: 120px;
  height: 120px;
  background-color: $gray-200;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.imgCntLogo {
  background-color: $gray-700;
}

.video {
  width: 300px;
  height: 179px;
  background-color: $gray-200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-img {
  .col {
    .info {
      text-overflow: ellipsis;
      padding-top: 6px;
    }
  }
}

.blqCard {
  height: 500px;
  .btnCell {
    position: relative;
    cursor: pointer;
    text-align: left;
    .arrow-down {
      position: absolute;
      bottom: 4;
      left: -32;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $gray-base;
    }
  }
}

.cardCollapse {
  cursor: pointer;
  .icon {
    margin-right: 12;
  }
}
.user-photo {
  object-fit: cover;
  margin-right: 12;
}

.stats-table {
  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 15px;
    .first {
      span {
        padding-left: 5px;
      }
    }
    .second {
      display: flex;
      div {
        padding-left: 5px;
      }
    }
  }
  .accordion-body {
    .list-group-item {
      .button-chapter {
        padding-left: 5px;
      }
    }
  }
}
